import http from "@/utils/services/http"

export const invoices = () => {
    let url = '/api/business/invoices'
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const invoice = (id) => {
    let url = `/api/business/invoices/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const payInvoice = (id, gateway, method) => {
    let url = `/api/business/invoices/${id}/pay`
    let config = {}
    config.timeout = 30000
    return http.post(url, {
        gateway: gateway,
        method: method
    }, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const checkStatusInvoice = (id) => {
    let url = `/api/business/invoices/${id}/status`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
