<template>
  <div>
    <div v-if="integrador.method === 'pix'">
      <div class="i-pix-qrcode">
        <img :src="qrcodePix" alt="QR Code">
        <div class="i-qr-legend">
          Acesse seu APP de pagamentos e faça a leitura do QR Code ao lado para efetuar o pagamento de forma rápida e segura.
        </div>
      </div>
      <div class="i-pix-payload">
        <div class="i-payload-legend">Código Pix Copia e Cola</div>
        <textarea v-model="integrador.extra.qrcode.payload" readonly />
        <div class="i-payload-btn">
          <u-btn :label="copiaLbl" @click="pixCopiaCola(integrador.extra.qrcode.payload)" no-caps class="sl-btn bg-black text-white" />
        </div>
      </div>
    </div>
    <div v-if="integrador.method === 'boleto'">
      <div class="i-pix-payload">
        <div class="text-center">
          Boleto gerado com sucesso!
        </div>
        <div class="text-center m-t m-b">
          <u-btn type="a" :href="integrador.extra.service.bankSlipUrl" target="_blank" no-caps class="sl-btn flex items-center justify-center" color="green"><i class="fa fa-file-pdf m-r-xs" /> Baixar Boleto</u-btn>
        </div>
        <div class="i-payload-legend">Linha Digitável</div>
        <div class="flex row justify-between">
          <div class="col-grow-1">
           <textarea v-model="integrador.extra.dadosBoleto.identificationField" readonly style="height: 40px; text-align: center; padding: 14px 0 0" />
          </div>
          <div class="m-l" style="max-width: 200px">
            <u-btn :label="copiaLbl" @click="pixCopiaCola(integrador.extra.dadosBoleto.identificationField)" no-caps class="sl-btn bg-black text-white" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CopyToClipboard from "@/utils/copy-to-clipboard"

export default {
  name: "InvoiceGenerator",
  props: {
    integrador: {
      required: true
    }
  },
  data () {
    return {
      copiaLbl: 'Copiar'
    }
  },
  computed: {
    qrcodePix () {
      return `data:image/png;base64,${this.integrador.extra.qrcode.encodedImage}`
    }
  },
  methods: {
    pixCopiaCola(d) {
      this.copiaLbl = 'Copiando...'
      CopyToClipboard(d)
          .then(() => {
            this.copiaLbl = 'Copiado!'
          })
          .catch(() => {
            this.$uloc.notify({
              color: 'negative',
              message: `Erro ao tentar copiar, tente copiar manualmente!`,
              position: 'bottom-left',
            })
          })
    }
  }
}
</script>

